html,
body {
  padding: 0;
  margin: 0;
  font-family: Plus Jakarta Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  white-space: pre-wrap;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-smoothing: antialiased;
}

button {
  font: 500 1em 'Plus Jakarta Sans';
}

ul {
  list-style-type: none;
  margin: 0;
  padding-left: 1rem;
}
li:before {
  content: '•';
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/* plus-jakarta-sans-regular - latin */
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-regular.svg#PlusJakartaSans')
      format('svg'); /* Legacy iOS */
}
/* plus-jakarta-sans-500 - latin */
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''), url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-500.woff') format('woff'),
    /* Modern Browsers */ url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-500.svg#PlusJakartaSans')
      format('svg'); /* Legacy iOS */
}
/* plus-jakarta-sans-600 - latin */
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''), url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-600.svg#PlusJakartaSans')
      format('svg'); /* Legacy iOS */
}
/* plus-jakarta-sans-700 - latin */
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''), url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-700.svg#PlusJakartaSans')
      format('svg'); /* Legacy iOS */
}
/* plus-jakarta-sans-800 - latin */
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''), url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-800.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-800.woff') format('woff'),
    /* Modern Browsers */ url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-800.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../public/assets/fonts/plus-jakarta-sans-v3-latin-800.svg#PlusJakartaSans')
      format('svg'); /* Legacy iOS */
}

/* montserrat-400 - latin */
@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../../public/assets/fonts/montserrat-v23-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('../../public/assets/fonts/montserrat-v23-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../public/assets/fonts/montserrat-v23-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../public/assets/fonts/montserrat-v23-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../../public/assets/fonts/montserrat-v23-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../public/assets/fonts/montserrat-v23-latin-regular.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../../public/assets/fonts/montserrat-v15-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url('../../public/assets/fonts/montserrat-v15-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../public/assets/fonts/montserrat-v15-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../public/assets/fonts/montserrat-v15-latin-500.woff') format('woff'),
    /* Modern Browsers */ url('../../public/assets/fonts/montserrat-v15-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../public/assets/fonts/montserrat-v15-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../../public/assets/fonts/montserrat-v15-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
    url('../../public/assets/fonts/montserrat-v15-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../public/assets/fonts/montserrat-v15-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../public/assets/fonts/montserrat-v15-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('../../public/assets/fonts/montserrat-v15-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../public/assets/fonts/montserrat-v15-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../../public/assets/fonts/montserrat-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url('../../public/assets/fonts/montserrat-v15-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../public/assets/fonts/montserrat-v15-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../public/assets/fonts/montserrat-v15-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../../public/assets/fonts/montserrat-v15-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../public/assets/fonts/montserrat-v15-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-800 - latin */
@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('../../public/assets/fonts/montserrat-v15-latin-800.eot'); /* IE9 Compat Modes */
  src: local('/assets/fonts/Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
    url('../../public/assets/fonts/montserrat-v15-latin-800.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../public/assets/fonts/montserrat-v15-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../public/assets/fonts/montserrat-v15-latin-800.woff') format('woff'),
    /* Modern Browsers */ url('../../public/assets/fonts/montserrat-v15-latin-800.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../public/assets/fonts/montserrat-v15-latin-800.svg#Montserrat') format('svg'); /* Legacy iOS */
}

.thumbnail-button {
  border: none;
}

/* Leaflet custom zoom buttons */
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  color: #e2001a !important;
  width: 42px !important;
  height: 34px !important;
  margin: 3px 0 0 !important;
  /* padding: 16.4px 15.3px 16.5px 15.3px  !important; */
  border-radius: 6px !important;
  border: solid 1px #ebebeb !important;
  background-color: #ffffff !important;
}

.leaflet-control-zoom-in:hover,
.leaflet-control-zoom-out:hover {
  background-color: #f7f7f7 !important;
}
.leaflet-control-zoom-in:active,
.leaflet-control-zoom-out:active {
  background-color: #ebebeb !important;
}

.leaflet-touch .leaflet-bar {
  border: none !important;
}
@-webkit-keyframes loaderPowerCalcFrameFin {
  0% {
    background: #f7f7f7;
  }
  10% {
    background: #e2001a;
  }
  40% {
    background: #f7f7f7;
  }
}

@-moz-keyframes loaderPowerCalcFrameFin {
  0% {
    background: #f7f7f7;
  }
  10% {
    background: #e2001a;
  }
  40% {
    background: #f7f7f7;
  }
}

@-o-keyframes loaderPowerCalcFrameFin {
  0% {
    background: #f7f7f7;
  }
  10% {
    background: #e2001a;
  }
  40% {
    background: #f7f7f7;
  }
}

@keyframes loaderPowerCalcFrameFin {
  0% {
    background: #f7f7f7;
  }
  10% {
    background: #e2001a;
  }
  40% {
    background: #f7f7f7;
  }
}

@keyframes inAnimation {
  0% {
    transform: translateY(-1000px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes outAnimation {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-1000px);
  }
}

body .loaderPowerCalcFrame:not(:required) {
  -webkit-animation: loaderPowerCalcFrameFin 2000ms 300ms infinite ease-out;
  -moz-animation: loaderPowerCalcFrameFin 2000ms 300ms infinite ease-out;
  -ms-animation: loaderPowerCalcFrameFin 2000ms 300ms infinite ease-out;
  -o-animation: loaderPowerCalcFrameFin 2000ms 300ms infinite ease-out;
  animation: loaderPowerCalcFrameFin 2000ms 300ms infinite ease-out;
  position: relative;
  text-indent: -9999px;
  margin: 1em auto;
}
body .loaderPowerCalcFrame:not(:required),
body .loaderPowerCalcFrame:not(:required):before,
body .loaderPowerCalcFrame:not(:required):after {
  background: #f7f7f7;
  -moz-border-radius: 0.625em !important;
  -webkit-border-radius: 0.625em !important;
  border-radius: 0.625em !important;
  height: 0.625em !important;
  width: 0.625em !important;
}
body .loaderPowerCalcFrame:not(:required):before {
  -webkit-animation: loaderPowerCalcFrameFin 2000ms 150ms infinite ease-out;
  -moz-animation: loaderPowerCalcFrameFin 2000ms 150ms infinite ease-out;
  -ms-animation: loaderPowerCalcFrameFin 2000ms 150ms infinite ease-out;
  -o-animation: loaderPowerCalcFrameFin 2000ms 150ms infinite ease-out;
  animation: loaderPowerCalcFrameFin 2000ms 150ms infinite ease-out;
  left: -1em;
}
body .loaderPowerCalcFrame:not(:required):after {
  -webkit-animation: loaderPowerCalcFrameFin 2000ms 450ms infinite ease-out;
  -moz-animation: loaderPowerCalcFrameFin 2000ms 450ms infinite ease-out;
  -ms-animation: loaderPowerCalcFrameFin 2000ms 450ms infinite ease-out;
  -o-animation: loaderPowerCalcFrameFin 2000ms 450ms infinite ease-out;
  animation: loaderPowerCalcFrameFin 2000ms 450ms infinite ease-out;
  right: -1em;
}
input:disabled {
  color: #a09e9e !important;
}

.grecaptcha-badge {
  z-index: 2;
}
textarea::-webkit-input-placeholder {
  color: #a09e9e;
}
textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #a09e9e;
}
textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #a09e9e;
}
textarea:-ms-input-placeholder {
  color: #a09e9e;
}
textarea::placeholder {
  color: #a09e9e;
}

ul.thumbnails-list li:before {
  content: none !important;
}
.gallery-figcaption--info {
  padding-top: 2rem !important;
  padding-bottom: 1rem !important;
}

.otpInput {
  color: #333333;
  font-family: 'Montserrat';
  outline: none;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  width: 40px !important;
  height: 40px;
  font-size: 1rem;
  font-weight: 600;
  background-color: #fafafa;
}

.otpInput:hover {
  border: 1px solid #5a585b;
}

.otpInputFocus {
  border: 1px solid #5a585b;
}

.otpInputError {
  border: 1px solid #e2001a;
  background-color: #fff;
}
